import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../core/services/notification.service';
import { LoginService } from '../login/services/login.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  ventasTienda: any;
  ventasCompania: any;
  ingresos: any;

  permisos: any;

  constructor(
    public notificationService: NotificationService,
    private accountService: LoginService,
  ) {
    this.permisos = this.accountService.permisosValue;
  }

  tienePermiso(modulo: string, permiso: string): boolean {
    return (
      this.permisos &&
      this.permisos[modulo] &&
      this.permisos[modulo][permiso] === '1'
    );
  }

  ngOnInit() {}
}
