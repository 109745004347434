<!-- home.component.html -->
<div class="welcome-container">
  <div style="gap: 16px" class="flex flex-col items-center justify-center">
    <h1>Bienvenido a fundcore</h1>
    <div
      style="cursor: pointer"
      *ngIf="tienePermiso('Punto_recaudo', 'Acceder')"
      routerLink="../recaudo/punto-recaudo"
      routerLinkActive="active"
    >
      <div class="feature flex flex-col">
        <span class="material-symbols-outlined">attach_money</span>
        <h2>Punto de recaudo</h2>
        <span
          style="color: var(--Azul-Sec-500)"
          class="items-center justify-center parrafo-bold"
        >
          <span class="material-symbols-outlined">arrow_forward</span>
        </span>
      </div>
    </div>
  </div>
</div>
