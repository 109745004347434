import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { LoginService } from '../../features/login/services/login.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private accountService: LoginService,
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    const user = this.accountService.userValue;
    const permissions = this.accountService.permisosValue;
    const token = localStorage.getItem('token-core');
    if (user && permissions && token) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
